<template>
  <div class="antialiased text-gray-900">
    <div class="mb-2 text-4xl font-extrabold text-center md:text-5xl">
      Where are the <span class="text-red-500">Shares</span>?
    </div>
    <div class="mb-2 text-xs italic text-center">
      Last updated Feb 5, 2021 4:41pm EST
    </div>
    <p class="pb-10 text-lg font-bold text-center">
      🚨 UPDATED CHARTS AND DATA 🚨
    </p>
    <app-chart />
    <div class="px-4 sm:px-12 md:px-24 lg:px-36 xl:px-48">
      <div class="mt-12 mb-2 text-2xl font-bold">What am I looking at?</div>
      <p class="mb-4">
        This chart shows outstanding shares of the company GameStop (in black)
        and the percent of those shares that failed to deliver (in red) on any
        given day. You can zoom in using the
        <span class="inline-block md:hidden">drop down menu</span
        ><span class="hidden md:inline-block">buttons</span> above the chart.
      </p>
      <p class="mb-4">
        Understanding the relative value (ie the percent) of Fails-To-Deliver to
        outstanding shares gives a better picture of the scope of the issue.
      </p>
      <p>
        Not enough people are talking about this. Would it be okay if 1%, 2%, 4%
        of your bank deposits failed to show up? If 3% of airplanes crashed?
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        What does Fails-to-Deliver mean?
      </div>
      <p>
        A failure to deliver (FTD) is the inability of a party to deliver a
        tradable asset, or meet a contractual obligation.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Why should I care?
      </div>
      <div>
        <iframe
          id="ytplayer"
          type="text/html"
          allowfullscreen="allowfullscreen"
          width="100%"
          height="360"
          src="https://www.youtube.com/embed/I0WXg5T3cBE?autoplay=1modestbranding=1&origin=https://wherearetheshares.com"
          frameborder="0"
        ></iframe>
        <p class="mt-2">
          Former Overstock.com CEO Dr. Patrick Byrne explains Fails-To-Deliver,
          its roots and risks, in terms anybody can understand.
        </p>
      </div>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Is there anything else interesting I should know?
      </div>
      <p>
        Gamestop was on the
        <a
          href="https://www.nyse.com/regulation/threshold-securities"
          target="_blank"
          class="text-red-500"
          >NYSE Threshold Security List</a
        >
        for <span class="font-bold">39 consecutive days</span> (Dec 8 - Feb 3).
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        What are Threshold Securities?
      </div>
      <p>
        Threshold securities are equity securities that have an aggregate fail
        to deliver position for five consecutive settlement days at a registered
        clearing agency (e.g., National Securities Clearing Corporation (NSCC))
        totaling 10,000 shares or more and equal to at least 0.5% of the
        issuer's total shares outstanding.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Okay, anything else?
      </div>
      <p class="pb-4">
        <a
          href="https://en.wikipedia.org/wiki/Michael_Burry"
          target="_blank"
          class="text-red-500"
          >Michael Burry</a
        >, the hedge fund manager of
        <a
          href="https://en.wikipedia.org/wiki/The_Big_Short_(film)"
          target="_blank"
          class="text-red-500"
          >The Big Short</a
        >, tweeted about GameStop (pictured below). The
        <a
          target="_blank"
          class="text-red-500"
          href="https://twitter.com/michaeljburry/status/1355221824661983233"
          >original tweet</a
        >
        has since been deleted but not before
        <a
          target="_blank"
          class="text-red-500"
          href="https://web.archive.org/web/20210130042803/https://twitter.com/michaeljburry/status/1355221824661983233"
          >archive.org</a
        >
        could capture it.
      </p>
      <img src="@/assets/michaeljburry-tweet.jpg" />
      <div class="mt-12 mb-2 text-2xl font-bold">
        There must be more, what else?
      </div>
      <p class="pb-4">
        <a
          href="https://www.nasdaq.com/market-activity/stocks/gme/institutional-holdings"
          target="_blank"
          class="text-red-500 "
          >111.03% of GameStop</a
        >
        is owned by Institutional investors.
      </p>
      <p class="pb-4">
        Institutional ownership is the amount of a company's available stock
        owned by mutual or pension funds, insurance companies, investment firms,
        private foundations, endowments or other large entities that manage
        funds on behalf of others.
      </p>
      <p class="pb-4">
        According to
        <a
          href="https://www.nasdaq.com/market-activity/stocks/gme/institutional-holdings"
          target="_blank"
          class="text-red-500"
          >Nasdaq</a
        >
        as of Feb 5, 2021 12:13pm EST, more stock is under Institutional
        ownership than supposedly even exists.
      </p>
      <img src="@/assets/gme-institutional-holdings.jpg" />
      <div class="mt-12 mb-2 text-2xl font-bold">
        How does GameStop FTD compare to others?
      </div>
      <p class="pb-4">
        That is a great question and it's important to understand if what we are
        seeing with Gamestop is the norm. In the analysis below, I look at three
        other retailers, Costco, Lowes and Target.
      </p>
      <img src="@/assets/gme-vs-retail-outstanding-shares-30d-avg.jpg" />
      <p class="pt-4">
        The Fails-To-Deliver (FTD) as a percent of outstanding shares is so
        small for all three of the other retailers that it barely shows up on
        the chart! We are talking
        <span class="font-bold">multiple orders of magnitude</span> smaller!
      </p>
      <p class="pt-4">
        This is not an exhaustive analysis, but based on comparing GameStop to
        other retailers, it appears GameStop Fails-To-Deliver (FTD) as a percent
        of outstanding shares is way outside the norm of what one would expect.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        What about FTD compared to trade volume?
      </div>
      <img class="pt-4" src="@/assets/gme-ftd-percent-trade-volume.jpg" />
      <p class="pt-2">
        As a percent of trade volume, GameStop Fails-To-Deliver bounces all over
        the place, at one point to nearly 60%! There are many days where a
        double digit percent of all shares traded in GameStop failed to deliver!
      </p>
      <img
        class="pt-4"
        src="@/assets/gme-ftd-percent-trade-volume-30d-avg.jpg"
      />
      <p class="pt-4">
        Smoothing out the data using a 30 day rolling average yields the chart
        shown above.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Okay, now compare GameStop to the other retailers.
      </div>
      <p class="pb-4">
        Taking trade volume into account and calculating the %FTD for GameStop,
        Costco, Lowes and Target yields the chart below.
      </p>
      <img src="@/assets/gme-vs-retail-trading-volume-30d-avg.jpg" />
      <p class="pt-4">
        The Fails-To-Deliver (FTD) as a percent of trade volume is again so
        small for all three of the other retailers that they are almost not
        noticeable on the chart.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        What can I do to help?
      </div>
      <p class="pb-4">
        <a
          href="https://democracy.io/"
          class="font-bold text-red-500"
          target="_blank"
        >
          Send a message to your representatives in Congress!</a
        >
      </p>
      <p class="pb-4">
        There will be upcoming Congressional Hearings on GameStop and RobinHood.
        Let them know that you want answers to why GameStops Fails-To-Deliver
        (FTD) rate is so high!
      </p>
      <p class="pb-4">
        <span class="font-bold">Share this site!</span> Post it on reddit,
        facebook, twitter, email, youtube, message it to friends. Anywhere!
        Please help spread the word!
      </p>
      <p>
        Let's not allow this to fade away and be forgotten. We need to demand
        answers to why GameStops Fails-To-Deliver rates are so high.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Where did you get the data from?
      </div>
      <p class="mb-4">
        Fails-To-Deliver data was collected and parsed together from hundreds of
        separate
        <a
          class="text-red-500"
          target="_blank"
          href="https://www.sec.gov/data/foiadocsfailsdatahtm"
          >Fails-to-Deliver Data</a
        >
        reports provided by the SEC.
      </p>
      <p class="mb-4">
        Shares Outstanding information was collected from
        <a
          class="text-red-500"
          target="_blank"
          href="https://ycharts.com/companies/GME/shares_outstanding"
          >Ycharts</a
        >.
      </p>
      <p class="mb-4">
        Historical trade volume for GameStop, Costco, Lowes and Target was
        sourced from
        <a
          class="text-red-500"
          target="_blank"
          href="https://support.google.com/a/users/answer/9308730?hl=en"
          >Google Sheets finance</a
        >.
      </p>
      <p>
        The percent FTD was calculated by taking daily Fails-To-Deliver and
        dividing it by that days outstanding shares.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Will you update this site when new data becomes available?
      </div>
      <p>
        Yes! I plan to keep an eye on the SEC Fails-to-Deliver report page. As
        soon as the January 2021 second half report becomes available, I will
        update this site.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Can I have the data you used?
      </div>
      <ul class="list-disc">
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/GME-FTD.csv"
            target="_blank"
            class="text-red-500"
          >
            GameStop FTD data (Jan 2010 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/GME-FTD-SHARES-OUTSTANDING-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >GameStop Outstanding Shares/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/COST-FTD-SHARES-OUTSTANDING-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >Costco Outstanding Shares/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/LOW-FTD-SHARES-OUTSTANDING-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >Lowes Outstanding Shares/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/TGT-FTD-SHARES-OUTSTANDING-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >Target Outstanding Shares/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/GME-FTD-TRADE-VOLUME-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >GameStop Trade Volume/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/COST-FTD-TRADE-VOLUME-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >Costco Trade Volume/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/LOW-FTD-TRADE-VOLUME-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >Lowes Trade Volume/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
        <li class="ml-6">
          <a
            href="https://wherearetheshares.com/TGT-FTD-TRADE-VOLUME-30D-AVG.csv"
            target="_blank"
            class="text-red-500"
            >Target Trade Volume/FTD data (Jan 2018 - Jan 2021)</a
          >
        </li>
      </ul>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Where can I learn more about counterfeiting stock?
      </div>
      <p>
        <a
          href="http://counterfeitingstock.com/CS2.0/CounterfeitingStock.html"
          target="_blank"
          class="text-red-500 break-words"
          >http://counterfeitingstock.com/CS2.0/CounterfeitingStock.html</a
        >
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Why did you make this site?
      </div>
      <p>
        As a shareholder with a small long position who has been following the
        GME saga closely, I am concerned about the integrity of the stock. I
        want more attention directed onto this issue.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Do you work in finance?
      </div>
      <p>
        No.
      </p>
      <div class="mt-12 mb-2 text-2xl font-bold">How can I contact you?</div>
      <p>wherearetheshares@gmail.com</p>
      <div class="mt-12 mb-2 text-2xl font-bold">
        Can I help with server costs?
      </div>
      I rather you share this information! But if you
      <a
        class="text-red-500"
        href="https://ko-fi.com/G2G53J1XD"
        target="_blank"
      >
        insist</a
      >. Thank you!
      <div class="mt-12 mb-2 text-2xl font-bold">Is this financial advice?</div>
      <p>No.</p>
      <div class="mt-12 mb-2 text-2xl font-bold">Disclaimer</div>
      This data was collected straight from the SEC but I do not guarantee
      anything. I do not claim it to be correct. All the data sources and data
      have been provided for you to verify.
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart";

export default {
  components: {
    "app-chart": Chart,
  },
  data() {
    return {};
  },
};
</script>
