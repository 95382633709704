<template>
  <div class="flex justify-center mb-4">
    <div>
      <div class="md:hidden">
        <select
          id="tabs"
          name="tabs"
          :required="true"
          class="block w-full border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
          @change="setRange($event.target.value)"
        >
          <option
            v-for="(r, idx) in ranges"
            :key="idx"
            :value="r.name"
            :selected="r.name == range.name"
          >
            {{ r.name }}
          </option>
        </select>
      </div>
      <div class="hidden md:block">
        <nav class="flex space-x-4" aria-label="Tabs">
          <a
            v-for="(r, idx) in ranges"
            :key="idx"
            class="px-3 py-2 text-sm font-medium text-center rounded-md cursor-pointer"
            :class="
              isActive(r)
                ? 'text-white bg-red-500 hover:text-white'
                : 'text-gray-500 hover:text-gray-700'
            "
            @click="setRange(r.name)"
          >
            {{ r.name }}
          </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="relative h-112">
    <canvas id="chart" />
  </div>
</template>

<script>
import Chart from "chart.js";
import chartData from "@/assets/gme-ftd-daily.json";

export default {
  data() {
    return {
      ctx: "",
      chart: "",
      ranges: [
        { name: "Since 2010", date: "1/4/10" },
        { name: "Since 2012", date: "1/3/12" },
        { name: "Since 2014", date: "1/2/14" },
        { name: "Since 2016", date: "1/4/16" },
        { name: "Since 2018", date: "1/4/18" },
        { name: "Since 2019", date: "1/3/19" },
        { name: "Since 2020", date: "1/2/20" },
      ],
      range: { name: "Since 2016", date: "1/4/16" },
      chartData: [],
      index: 0,
    };
  },

  mounted() {
    this.index = chartData.findIndex(
      (data) => data["DATETIME"] == this.range.date
    );
    this.chartData = chartData.slice(this.index);
    this.ctx = document.getElementById("chart");
    this.generateChart();
  },

  methods: {
    isActive(r) {
      return r.name === this.range.name;
    },
    setRange(payload) {
      this.range = this.ranges.find((r) => r.name === payload);
      this.index = chartData.findIndex(
        (data) => data["DATETIME"] == this.range.date
      );
      this.chart.destroy();
      this.chartData = chartData.slice(this.index);
      this.ctx = document.getElementById("chart");
      this.generateChart();
    },
    generateChart() {
      this.chart = new Chart(this.ctx, {
        type: "bar",
        data: {
          labels: this.chartData.map((d) => d.DATETIME),
          datasets: [
            {
              barPercentage: 1,
              label: "% FAILURE TO DELIVER",
              borderColor: "#da362c",
              backgroundColor: "#da362c",
              yAxisID: "% FTD",
              data: this.chartData.map((d) => d["% FTD"] * 100),
              fill: false,
            },
            {
              barPercentage: 1,
              label: "SHARES OUTSTANDING",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              yAxisID: "SHARES OUTSTANDING",
              data: this.chartData.map((d) => d["SHARES OUTSTANDING"]),
              fill: false,
              type: "bar",
              order: 2,
            },
          ],
        },
        options: {
          animation: {
            duration: 0,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "bottom",
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItems, data) {
                return `${data.datasets[1].label}: ${data.datasets[1].data[
                  tooltipItems.index
                ].toLocaleString()} (${Math.round(
                  data.datasets[0].data[tooltipItems.index] * 100
                ) / 100}% FTD)`;
              },
            },
          },
          scales: {
            yAxes: [
              {
                id: "% FTD",
                type: "linear",
                position: "right",
                ticks: {
                  beginAtZero: true,
                  callback: function(value) {
                    return `${Math.round(value * 10) / 10}%`;
                  },
                },
              },
              {
                id: "SHARES OUTSTANDING",
                type: "linear",
                position: "left",
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                  max: 180000000,
                  callback: function(value) {
                    return value.toLocaleString();
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>
